import { defineComponent, ref } from '@vue/composition-api';

import pCover from './icons/headphones.svg';
import pFrid from './icons/radio.png';
import pFav from './icons/soundcloud.png';
import pPart from './icons/speaker-2.png';
import pMusic from './icons/music.svg';
import pKara from './icons/micro.svg';

export default defineComponent({
	setup() {
		const data = ref([
			{ icon: pMusic, key: 'original', text: '原创作品' },
			{ icon: pPart, key: 'part', text: '参与创作' },
			{ icon: pFrid, key: 'friends', text: '网友作品' },
			{ icon: pCover, key: 'cover', text: '翻唱填词' },
			{ icon: pFav, key: 'favorite', text: '音乐收藏' },
			{ icon: pKara, key: 'karaoke', text: '伴奏' },
		]);
		return { data };
	},
});
