import { defineComponent } from '@vue/composition-api';
import Card from '@/components/Card';
import MusicCategoryLite from '@/components/Article/MusicCategoryLite';

export default defineComponent({
	components: { Card, MusicCategoryLite },
	setup() {
		return {};
	},
});
